/**
 * Single page for books
 */

// Import statements
import React from "react"
import {graphql, Link} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"
import parse from "html-react-parser"
import "../styles/files/single-post.scss"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
//import "../styles/@wordpress/block-library/build-style/style.styles"
//import "../styles/@wordpress/block-library/build-style/theme.styles"
import Layout from "../components/layout"
import Body from "../components/body"
import Seo from "../components/common/seo"
import {formatDate} from "../components/common/format-date";
import AdFooter from "../components/ad/ad-footer";
import {BookRating} from "../components/common/rating";
import Author from "../components/common/author";
import AdSkyscraper from "../components/ad/ad-skyscraper";
import InstagramFeed from "../components/common/instagram-feed";
import SocialMedia from "../components/common/socialmedia";
import BlogComments from "../components/comment/comments";

/**
 * Define the BookPostTemplate constant
 * @param previous
 * @param next
 * @param book
 * @param comments
 * @returns {JSX.Element}
 */
const BookPostTemplate = ({data: {previous, next, book, comments}}) => {

    // get the featured image
    const featuredImage = {
        data: book.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
        alt: book.featuredImage?.node?.alt || ``,
    }

    // get publicatuon date of the post
    const publication_date = new Date(book.date);

    // get publication date of the book
    const book_publication_date = new Date(book.publicationDate * 1000);

    // get HTML
    return (
        <Layout>
            <Body bodyClass="single single-book"/>
            <Seo title={book.title} description={book.excerpt}/>

            <article className="post">

                <header className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1">
                        <h1 className="entry-title" itemProp="name">{parse(book.title)}</h1>
                        {(book.authorsUrlText !== "" || book.authorsText !== "" || book.book_publication_date !== "") && (
                            <ul className="list-inline uppercase">
                                {book.authorsUrlText && (
                                    <li key="website">
                                        <a href={book.authorsUrlText} title={book.authorsText}>{book.authorsText}</a>
                                    </li>
                                )}
                                {book_publication_date && (
                                    <li key="book_publication_date">{formatDate(book_publication_date)}</li>
                                )}
                            </ul>
                        )}
                        {(book.isbnText !== "" || book.priceText !== "") && (
                            <ul className="list-inline uppercase">

                                {book.priceText && (
                                    <li key="price">Adviesprijs: {book.priceText.replace('.', ',')}</li>
                                )}
                                {book.isbnText && (
                                    <li key="isbn">ISBN: {book.isbnText}</li>
                                )}
                                {book.bookUrlText && (
                                    <li key="website">
                                        <a href={book.bookUrlText} title={book.title}>Officiele website</a>
                                    </li>
                                )}
                            </ul>
                        )}

                        <ul className="list-inline uppercase">
                            <li key="publication_date">Gereviewd op {formatDate(publication_date)}</li>
                            <li key="author">Review door {book.author.node.uri && (
                                <Link to={book.author.node.uri}>{book.author.node.name}</Link>
                            )}</li>
                        </ul>
                    </div>
                </header>

                <div className="row" id="header-single-image">
                    <nav className="d-flex justify-content-between">
                        <ul className="pager">
                            {previous && (
                                <li className="pager-button btn btn-grey previous">
                                    <Link to={previous.uri} rel="prev" className="tip tip-left"
                                          data-title={parse(previous.title)}>
                                        ← vorige
                                    </Link>
                                </li>
                            )}
                            {next && (
                                <li className="pager-button btn btn-grey next">
                                    <Link to={next.uri} rel="next" className="tip tip-right"
                                          data-title={parse(next.title)}>
                                        volgende →
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </nav>
                    <div className="col-md-10 offset-md-1">
                        {/* if we have a featured image for this post let's display it */}
                        {featuredImage?.data && (
                            <GatsbyImage
                                image={featuredImage.data}
                                alt={featuredImage.alt}
                            />
                        )}
                    </div>
                </div>

                <div className="row single-content" id="post-content">
                    <div className="col-md-8 col-lg-7 offset-lg-1 order-1 col-no-padding" id="post-content-body">

                        <AdFooter/>

                        <div id="single-blog-text">
                            <div id="result"></div>
                            {!!book.content && parse(book.content)}

                            {book.prosWysiwyg && (
                                <h2>Pluspunten</h2>
                            )}
                            {!!book.prosWysiwyg && parse(book.prosWysiwyg)}

                            {book.consWysiwyg && (
                                <h2>Minpunten</h2>
                            )}
                            {!!book.consWysiwyg && parse(book.consWysiwyg)}
                        </div>
                        <div id="single-footer">
                            <BookRating rating={book.rating}/>
                            <div id="author-single-meta">
                                <Author author={book.author.node}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 order-2" id="sidebar-right">
                        <AdSkyscraper/>
                        <InstagramFeed/>
                        <SocialMedia title={book.title} featuredImage={featuredImage}/>
                    </div>
                </div>

                <BlogComments post={book} comments={comments}/>

            </article>

        </Layout>
    )
}

/**
 * Export the BookPostTemplate variable
 */
export default BookPostTemplate

/**
 * Get the single book with GraphQL
 * @type {StaticQueryDocument}
 */
export const pageQuery = graphql`
  query BookPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    book: wpBook(id: { eq: $id }) {
        id
        content
        title
        authorsText
        authorsUrlText
        bookUrlText
        publicationDate
        priceText
        isbnText
        rating
        consWysiwyg
        prosWysiwyg
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 50
                  placeholder: DOMINANT_COLOR
                  layout: FULL_WIDTH
                  breakpoints: [546,605,805,955,1105]
                )
              }
            }
          }
        }
        author {
          node {
            databaseId
            name
            uri
            description
            nicename
            email
            slug
            avatar {
              height
              size
              url
              width
            }
          }
        }
      }
      comments: allWpComment(filter: {commentedOn: {node: {id: { eq: $id } } } } ) {
        edges {
          node {
            id
            databaseId
            author {
              node {
                id
                name
                email
                url
                databaseId
              }
            }
            date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
            content
            parentDatabaseId
          }
        }
      }
      previous: wpBook(id: { eq: $previousPostId }) {
        uri
        title
      }
      next: wpBook(id: { eq: $nextPostId }) {
        uri
        title
      }
  }
`
